<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('farm_report.distribution_report_title') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                    <b-row>
                        <!-- fiscal year id -->
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="fiscal_year_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="search.fiscal_year_id"
                                        id="fiscal_year_id"
                                        :options="fiscalYearList"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <!-- season -->
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Season" vid="season_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="season_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('farm_config.season')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="search.season_id"
                                        id="season_id"
                                        :options="seasonList"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <!-- circular -->
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Circular" vid="circular_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="circular_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('farm_config.circular')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                        plain
                                        :disabled="circularLoading ? true : false"
                                        v-model="search.circular_id"
                                        id="circular_id"
                                        :options="circularList"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                      <template v-slot:first>
                                            <b-form-select-option :value="0">{{circularLoading ? $t('globalTrans.loading') + '...' : $t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <!-- circular -->
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Supplier" vid="supplier_id" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="supplier_id"
                                    slot-scope="{ errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('farm_allocation.supplier')}} <span class="text-danger">*</span>
                                    </template>
                                    <v-select name="supplier_id"
                                        v-model="search.supplier_id"
                                        label="text"
                                        :reduce="item => item.value"
                                        :options= supplierList
                                        :placeholder="$t('globalTrans.select')"
                                        />
                                    <div class="invalid-feedback d-block">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                      <b-col cols="12">
                        <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row >
          <b-col md="12" v-if="showData">
            <b-overlay>
              <iq-card>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('farm_report.distribution_report_title') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <!-- <b-button class="btn_add_new" @click="pdfExport">
                            <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                          </b-button> -->
                        </template>
                        <template v-slot:body>
                          <b-overlay :show="loading">
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :base-url="incentiveGrantServiceBaseUrl" uri="/common-config/report-heading/detail" :org-id="2">
                                        <template v-slot:projectNameSlot>
                                          {{ }}
                                        </template>
                                        {{currentLocale === 'bn' ? 'সরবরাহকারী অনুযায়ী কৃষি যন্ত্র বিতরন প্রতিবেদন' : 'Supplier wise Agriculture Machinery Distribution Report'}}
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                <div class="text-black">
                                <b-row>
                                  <b-col md="4"><strong>{{ $t('globalTrans.fiscal_year') }}: </strong>{{ search.fiscal_year_id ? getFiscalYearName(search.fiscal_year_id) : $t('globalTrans.all')  }}</b-col>
                                  <b-col md="4"><strong>{{ $t('farm_allocation.season') }}: </strong>{{ search.season_id  ?  getSeasonName(search.season_id) : $t('globalTrans.all') }}</b-col>
                                  <b-col md="4"><strong>{{ $t('farm_allocation.circular') }}: </strong>{{ search.circular_id ? getCircularName(search.circular_id) : $t('globalTrans.all')  }}</b-col>
                                </b-row>
                              </div>
                                <template>
                                  <b-table-simple class="tg mt-3" bordered hover row-hovered small caption-top responsive show-empty>
                                    <b-thead>
                                        <tr>
                                            <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th class="text-center">{{ $t('farm_config.company_name') }}</b-th>
                                            <b-th class="text-center">{{ $t('farm_config.machine_name') }}</b-th>
                                            <b-th class="text-center">{{ $t('farm_config.brand') }}</b-th>
                                            <b-th class="text-center">{{ $t('farm_config.country_origin_name') }}</b-th>
                                            <b-th class="text-center">{{ $t('farm_config.country_prepared_name') }}</b-th>
                                            <b-th class="text-center">{{ $t('farm_config.horse_power') }}</b-th>
                                            <b-th class="text-center">{{ $t('farm_config.model_no') }}</b-th>
                                            <b-th class="text-center">{{ $t('farm_config.extended_model_no') }}</b-th>
                                            <b-th class="text-center">{{ $t('farm_allocation.engine_no') }}</b-th>
                                            <b-th class="text-center">{{ $t('farm_allocation.chasis_no') }}</b-th>
                                        </tr>
                                    </b-thead>
                                    <b-tbody>
                                        <template v-if="Object.keys(datas).length > 0">
                                          <slot v-for="(item, index) in datas">
                                            <b-tr v-for="(supplierItem, supplierIndex) in item.suppliers_datas" :key="'suplier-info-' + supplierIndex">
                                                <b-td  class="text-center">{{$n(index + 1)}}: {{ $n(supplierIndex + 1) }}</b-td>
                                                <b-td class="text-center" v-if="supplierIndex === 0" :rowspan="item.suppliers_datas.length">{{ getSupplierName(item.supplier_id ) }}</b-td>
                                                <b-td class="text-center">{{ getInstrumentName(supplierItem.instrument_name_id) }}</b-td>
                                                <b-td class="text-center">{{ getBrandName(supplierItem.brand_id) }}</b-td>
                                                <b-td class="text-center">{{ getCountryOriginName(supplierItem.county_origin_id) }}</b-td>
                                                <b-td class="text-center">{{ getCountryManufactureName(supplierItem.county_manufacturer_id) }}</b-td>
                                                <b-td class="text-center">{{ supplierItem.engine_power }}</b-td>
                                                <b-td class="text-center">{{ supplierItem.model }}</b-td>
                                                <b-td class="text-center">{{ supplierItem.extend_model ? supplierItem.extend_model : 'N/A' }}</b-td>
                                                <b-td class="text-center">{{ supplierItem.engine_no }}</b-td>
                                                <b-td class="text-center">{{ supplierItem.chasis_no }}</b-td>
                                            </b-tr>
                                          </slot>
                                        </template>
                                        <template v-else>
                                            <b-tr>
                                                <b-td colspan="11" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                            </b-tr>
                                        </template>
                                    </b-tbody>
                                  </b-table-simple>
                                </template>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import { core } from '@/config/pluginInit'
  import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
  import { distributionReportApi, circularListApi, distributedSupplierListApi } from '../../api/routes'
  import flatpickr from 'flatpickr'
  import ListReportHead from '@/components/custom/ListReportHead.vue'
  import ExportPdf from './export-pdf_details'
  import excel from 'vue-excel-export'
  import Vue from 'vue'
  Vue.use(excel)

  export default {
      props: ['id'],
      components: {
          ListReportHead
      },
      created () {
        this.getCircularList()
        this.getSupplierList()
      },
      data () {
          return {
            search: {
              circular_id: 0,
              season_id: 0,
              fiscal_year_id: 0
            },
            machineTypeList: [],
            loadMechineType: false,
            circularList: [],
            circularLoading: false,
            incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
            datas: [],
            supplierList: [],
            loading: false,
            showData: false
          }
      },
      mounted () {
          core.index()
          flatpickr('.datepicker', {})
      },
      computed: {
        currentLocale () {
          return this.$i18n.locale
        },
        seasonList: function () {
          return [
            { value: 1, text: this.$i18n.locale === 'en' ? 'Aus' : 'আউশ', text_en: 'Aus', text_bn: 'আউশ' },
            { value: 2, text: this.$i18n.locale === 'en' ? 'Amon' : 'আমন', text_en: 'Amon', text_bn: 'আমন' },
            { value: 3, text: this.$i18n.locale === 'en' ? 'Boro' : 'বোরো', text_en: 'Boro', text_bn: 'বোরো' }
          ]
        },
        fiscalYearList () {
          return this.$store.state.commonObj.fiscalYearList
        }
      },
      watch: {
        'search.fiscal_year_id': function (newVal, oldVal) {
          if (newVal) {
            this.search.circular_id = 0
            this.getCircularList()
          }
        },
        'search.season_id': function (newVal, oldVal) {
          if (newVal) {
            this.search.circular_id = 0
            this.getCircularList()
          }
        },
        currentLocale: function (newVal, oldVal) {
          this.getCircularList()
        }
      },
      methods: {
        async getCircularList () {
          this.circularLoading = true
          const params = {
            fiscal_year_id: this.search.fiscal_year_id,
            season_id: this.search.season_id,
            lang: this.currentLocale
          }
          let result = null
          result = await RestApi.getData(incentiveGrantServiceBaseUrl, circularListApi, params)
          if (result.success) {
              this.circularList = result.data
          } else {
              this.circularList = []
          }
          this.circularLoading = false
        },
        pdfExport () {
            const reportTitle = this.$t('farm_report.farm_report_title')
            ExportPdf.exportPdfDetails(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail', 2, reportTitle, this.datas, this, this.searchResult)
        },
        async searchData () {
          var check = await this.$refs.form.validate()
          if (check) {
          this.loading = true
          this.showData = true
          const params = Object.assign({}, this.search)
          await RestApi.getData(incentiveGrantServiceBaseUrl, distributionReportApi, params).then(response => {
            if (response.success) {
              this.datas = response.data
            } else {
              this.datas = []
            }
          })
          this.loading = false
          }
        },
        async getSupplierList () {
          const params = Object.assign({}, { lang: this.currentLocale })
          let result = null
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          result = await RestApi.postData(incentiveGrantServiceBaseUrl, distributedSupplierListApi, params)
          if (result.success) {
              this.supplierList = result.data
          } else {
            this.supplierList = []
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        },
        getFiscalYearName (id) {
          const obj = this.$store.state.commonObj.fiscalYearList.find(item => item.value === id)
          if (obj !== undefined) {
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
          }
        },
        getSeasonName (id) {
          const obj = this.seasonList.find(item => item.value === id)
          if (obj !== undefined) {
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
          }
        },
        getCircularName (id) {
          const obj = this.$store.state.incentiveGrant.commonObj.circularList.find(item => item.value === id)
          if (obj !== undefined) {
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
          }
        },
        getMachineTypeName (id) {
          const obj = this.$store.state.incentiveGrant.commonObj.macMachineTypeList.find(el => el.value === id)
          if (obj !== undefined) {
            return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
          }
        },
        getInstrumentName (id) {
            const obj = this.$store.state.incentiveGrant.commonObj.instrumentList.find(item => item.value === id)
            if (obj !== undefined) {
              return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            }
        },
        getSupplierName (id) {
            const obj = this.supplierList.find(item => item.value === id)
            if (obj !== undefined) {
              return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            }
        },
        getBrandName (id) {
            const obj = this.$store.state.incentiveGrant.commonObj.macBrandList.find(item => item.value === id)
            if (obj !== undefined) {
              return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            }
        },
        getCountryOriginName (id) {
            const obj = this.$store.state.commonObj.countryList.find(el => el.value === parseInt(id))
            if (obj !== 'undefinded') {
                return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
            }
        },
        getCountryManufactureName (id) {
            const obj = this.$store.state.commonObj.countryList.find(el => el.value === parseInt(id))
            if (obj !== 'undefinded') {
                return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
            }
        }
      }
  }
  </script>
